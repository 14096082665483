import React from 'react';
import './csoon.css';


interface Props {}


const Csoon =(props:Props) => {
  
    return (
     
    <div className="csoon">
      <h1>Coming Soon!</h1>
  <p>We're working hard to bring you something amazing.</p>
  <p>Stay tuned for updates.</p>
  <a href='/'><p>Back to Home Page</p></a>
    </div>
    );
};

export default Csoon;
