// Importing the checkmark SVG

import './VS.css'
import CheckmarkIcon from '../Assets/CheckMark.svg';
import recon from '../Assets/attack-surface-mapping-illustration.svg'


interface Props {}

// Card Component
const VS = (props:Props) => {
  return (
    <div className='main-card'>
        <div className='title-gradient'>Vulnerability Scanning</div>
    
    <div className='card-horizontal'>
    <div className='card-column'>
    <div className='title text'>Network Scan</div>
    <ul className='checklist-text'>
      <li><img src={CheckmarkIcon}/>Detect XSS, SQLi and address misconfigurations in both<br/>
classic and modern web applications, including SPAs.</li>
<li><img src={CheckmarkIcon}/>Quickly pinpoint security issues with dedicated scanners<br/>
for API vulnerabilities and popular CMSs (Wordpress,<br/>
Drupal, Joomla, Sharepoint)</li>

    </ul>
    <a href='/scanpage'><button className='card-btn'><span className='card-btn-txt'>Explore Web Application Scanners</span></button></a>
  </div>
  <div className='card-column'>
    <div className='title text'>Network Scan</div>
    <ul className='checklist-text'>
      <li><img src={CheckmarkIcon}/>Utilize Our Network Vulnerability Scanner to identify<br/>
SSL/TLS verification, DNS scan, address missing security<br/>
patches, and tackle critical CVEs like Log4Shell and<br/>
ProxyShell. Uncover the risk of unauthorized access by<br/>
identifying weak credentials with our Password Auditor<br/>
tool</li>
    </ul>
    <button className='card-btn'><span className='card-btn-txt'>Explore Network Scanners</span></button>
  </div>
  </div>
  </div>
  );
};

export default VS;
