import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Homepage/home";
import Login from "../pages/LoginPage/login";
import Signup from "../pages/SignupPage/signup";
import Scanpage from "../pages/ScanPage/Scanpage";
import Csoon from "../pages/ComingSoon/csoon";
import CVEList from "../pages/CVE/cve";
import Terminal from "../pages/Terminal/terminal"
export const router= createBrowserRouter([
    {
        path: "/",
        element:<App />,
        children: [
            {path: "/", element: <Home />},
            {path: "/login", element: <Login
            onLogin={(email, password) => console.log(email, password)}
          />},
          {path: "/signup", element: <Signup
            onSignup={(fullname,email, password) => console.log(fullname,email, password)}
          />},
          {path: "/Scanpage", element: <Scanpage />},
          {path: "/comingsoon", element: <Csoon />},
          {path: "/cve", element: <CVEList />} ,
          {path: "/terminal", element: <Terminal />}         


        ],
    },
])