// Importing the checkmark SVG

import './WPR.css'
import CheckmarkIcon from '../Assets/CheckMark.svg';
import recon from '../Assets/writing-pentest-reports-illustration.png'


interface Props {}

// Card Component
const WPR = (props:Props) => {
  return (
    <div className='card-horizontal'>
    <div className='card-column'>
    <div className='title-gradient'>Attack Surface Analysis</div>
    <ul className='checklist-text'>
      <li><img src={CheckmarkIcon}/>Create Word (.docx) reports 50% faster than the manual process. Streamline report<br/>
writing using predefined Word templates and a comprehensive library of common<br/>
findings, complete with descriptions, risk assessments, and recommendations.</li>
    </ul>
    <a href='/comingsoon'><button className='card-btn'><span className='card-btn-txt'>Learn about reporting</span></button></a>
  </div>
  <div className='card-column'>
    <img className='wpr-img' src={recon}/>
  </div>
  </div>
  );
};

export default WPR;
