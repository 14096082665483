import Nav from '../NavBar/nav';
import Footer from '../Footer/footer';
import Scan from '../ScanComponet/Scan';

interface Props {}

const Scanpage =(props:Props) => {
  
    return (
      <div className='main'>
       <Nav/>
       <Scan/>
       <Footer/>
      </div>
    );
};

export default Scanpage;
