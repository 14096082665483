import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface CVEItem {
  cve: {
    CVE_data_meta: {
      ID: string;
    };
    description: {
      description_data: {
        value: string;
      }[];
    };
  };
}

const CVEList: React.FC = () => {
  const [cves, setCves] = useState<CVEItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCVEs = async () => {
      try {
        // Fetch CVE data from NVD API
        const response = await axios.get('https://services.nvd.nist.gov/rest/json/cves/2.0');
        // Extract CVE items from the response
        const cveItems: CVEItem[] = response.data.result.CVE_Items;
        // Store the CVE items in the state
        setCves(cveItems);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching CVE data:', error);
      }
    };

    fetchCVEs();
  }, []);

  return (
    <div>
      <h1>Common Vulnerabilities and Exposures (CVEs)</h1>
      {loading ? (
        <p>Loading CVE data...</p>
      ) : (
        <ul>
          {cves.map((cveItem, index) => (
            <li key={index}>
              <strong>{cveItem.cve.CVE_data_meta.ID}</strong>: {cveItem.cve.description.description_data[0].value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CVEList;
