import React from 'react';
import './login.css';
import illustrationPath from '../Assets/Login-Illustration.svg'
import { ReactComponent as GoogleIcon } from '../Assets/Globe.svg'; // Update with the real path to your SVG
import { Container } from 'react-bootstrap';

interface LoginProps {
  onLogin: (email: string, password: string) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin}) => {
  return (
    <div className="login-container">            
     <a className='logo-a'href='/'><h1>SecurProbe</h1></a>
     <Container className='content-container'>

    <div className='column'>
      <div className="login-illustration">
        <img src={illustrationPath} alt="Login Illustration" />
      </div>
      </div>
      <div className='column'>
      <div className="login-form-container">
        <h2>Login</h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          const email = e.currentTarget.email.value;
          const password = e.currentTarget.password.value;
          onLogin(email, password);
        }}>
          <div className="form-group">
            <label>Email</label>
            <input name="email" type="email" placeholder="Your Email" />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input name="password" type="password" placeholder="Your Password" />
            <label className="remember-me">
              <input type="checkbox"/>
              Remember me
            </label>
            <a href="#" className="forgot-password">Forgot Password?</a>            
          </div>
          <div className="form-footer">
            
            <button type="submit" className="login-button">Log in</button>
            <button type="button" className="google-login-button">
              <GoogleIcon className="google-icon" />
              Log in with Google
            </button>
            <p className="sign-up">
              Need an account? <a href="/signup">Sign up here</a>
            </p>
          </div>
        </form>
      </div>
      </div>
      </Container>
    </div>
  );
};

export default Login;
