// Importing the checkmark SVG

import './WSCard.css'
import CheckmarkIcon from '../Assets/CheckMark.svg';

interface Props {}

// Card Component
const WSCard = (props:Props) => {
  return (
    <div className="card-container">
      <h2 className="card-title">Web Developer</h2>
      <ul className='WSCard-list'>
      <li className="checkmark-section">
        <h3 className="header-text"><img src={CheckmarkIcon}/>Ongoing Exploits:</h3>
        <p className="body-text">
          Review ongoing exploits and CVEs to detect potential security threats.
          Take preemptive action to prevent security incidents before they escalate.
        </p>
      </li>
      <li className="checkmark-section">
        <h3 className="header-text"><img src={CheckmarkIcon}/>Basic Security Hygiene Checks:</h3>
        <p className="body-text">
          Run quick and effective security hygiene checks for foundational protection.
          Export simple reports for easy comprehension and documentation.
        </p>
      </li>
      <li className="checkmark-section">
        <h3 className="header-text"><img src={CheckmarkIcon}/>Automated Security Compliance Scans:</h3>
        <p className="body-text">
          Perform and automate scans to ensure adherence to security compliance standards.
          Enhance efficiency by automating routine checks and ensuring continuous compliance.
        </p>
      </li>
      <li className="checkmark-section">
        <h3 className="header-text"><img src={CheckmarkIcon}/>Pre-Audit Security Posture Assessment:</h3>
        <p className="body-text">
          Assess your security posture proactively before external audits.
          Identify and rectify vulnerabilities to bolster your defenses in preparation for audits.
        </p>
      </li>
      <li className="checkmark-section">
        <h3 className="header-text"><img src={CheckmarkIcon}/>Misconfiguration Detection in Web Servers and Applications:</h3>
        <p className="body-text">
          Check for misconfigurations in web servers and applications to mitigate potential security risks.
          Improve overall system resilience by addressing configuration vulnerabilities.
        </p>
      </li>
      <li className="checkmark-section">
        <h3 className="header-text"><img src={CheckmarkIcon}/>Patch Management for Servers and Workstations:</h3>
        <p className="body-text">
          Discover missing patches on servers and workstations to prevent exploitation of known vulnerabilities.
        </p>
      </li>
      </ul>
    </div>
  );
};

export default WSCard;
