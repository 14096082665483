// import React, { useState } from 'react';
// import axios from 'axios';
// import './Scan.css';

// // function Scan() {
// //   const [inputData, setInputData] = useState('');
// //   const [result, setResult] = useState('');

// //   const sendDataToFlask = () => {
// //     console.log(inputData)
// //     axios.post('http://127.0.0.1:5000/process_data', { inputData })
// //       .then(response => {
// //         setResult(response.data.result);
// //       })
// //       .catch(error => {
// //         console.error('Error:', error);
// //       });
// //   };

// //   return (
// //     <div>
// //       <input type="text" value={inputData} onChange={(e) => setInputData(e.target.value)} />
// //       <button onClick={sendDataToFlask}>Send Data</button>
// //       <h1>Result: {result}</h1>
// //     </div>
// //   );
// // }

// // export default Scan;


// const Scan: React.FC = () => {
//   const [textInput, setTextInput] = useState('');
//   const [selectedOption, setSelectedOption] = useState('');
//   const [result, setResult] = useState('');

//   const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTextInput(e.target.value);
//   };

//   const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setSelectedOption(e.target.value);
//   };

//   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     // Do something with textInput and selectedOption, e.g., send to backend
//     setResult('');
//     console.log('Text Input:', textInput);
//     console.log('Selected Option:', selectedOption);
//     axios.post('http://127.0.0.1:5000/process_data', {textInput,selectedOption})
//       .then(response => {
//         // setResult(response.data.result);
//         const convertedResult = convertBrToNewline(response.data.result);
//         setResult(convertedResult);
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       });
//   };

//   const convertBrToNewline = (str: string) => {
//     // Replace <br> with newline characters (\n)
//     return str.replace(/<br\s*\/?>/g, '\n');
//   };

//   return (
//     <div className="containerscan">
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={textInput}
//           onChange={handleTextChange}
//           placeholder="Enter Target here"
//         />
//         <select value={selectedOption} onChange={handleOptionChange}>
//           <option value="">Select Scanner</option>
//           <option value="recon">Reconnaissance</option>
//           <option value="option2">Option 2</option>
//           <option value="option3">Option 3</option>
//         </select>
//         <button type="submit">Submit</button>
//       </form>

//       <div className='Result'>
//         <p style={{ textAlign: 'left' }}>The Recon Result for {textInput}</p>
//         <pre style={{ textAlign: 'left' }}>{result}</pre>
//       </div>
//     </div>
//   );
// };

// export default Scan;


import React, { useState } from 'react';
import axios from 'axios';
import './Scan.css';

const Scan: React.FC = () => {
  const [textInput, setTextInput] = useState('');
  const [selectedScanner, setSelectedScanner] = useState('');
  const [selectedSubOption, setSelectedSubOption] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value);
  };

  const handleScannerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedScanner(e.target.value);
    setSelectedSubOption('');  // Reset sub-option when main option changes
  };

  const handleSubOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubOption(e.target.value);
  };

  // const validateURL = (url: string) => {
  //   const urlPattern = new RegExp(
  //     '^(https?:\\/\\/|www\\.)' + // ensure the URL starts with http://, https://, or www.
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}\\.([a-z]{2,})|' + // validate the domain name with a TLD
  //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR validate the IP (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate the port and path (optional)
  //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate the query string (optional)
  //     '(\\#[-a-z\\d_]*)?$', 'i' // validate the fragment locator (optional)
  //   );
  //   return urlPattern.test(url);
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setResult('');
    // if (!validateURL(textInput)) {
    //   setResult('Please enter a valid URL starting with http://, https://, or www.');
    //   return;
    // }
    
  
    // Do something with textInput, selectedScanner, and selectedSubOption, e.g., send to backend
    
    console.log('Text Input:', textInput);
    console.log('Selected Scanner:', selectedScanner);
    console.log('Selected Sub-Option:', selectedSubOption);
    axios.post('https://sparrow-measured-roughy.ngrok-free.app/process_data', {
      textInput,
      selectedSubOption
    })
      .then(response => {
        const convertedResult = convertBrToNewline(response.data.result);
        setResult(convertedResult);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const convertBrToNewline = (str: string) => {
    return str.replace(/<br\s*\/?>/g, '\n');
  };

  // Options for the second dropdown based on the selectedScanner
  const getSubOptions = () => {
    switch (selectedScanner) {
      case 'recon':
        return (
          <>
            <option value="">Select Tool*</option>
            <option value="ipwhois">Ip Whois</option>
            <option value="dig">Default Dig tool</option>
            <option value="sub_domain">Sub Domain Finder</option>
            <option value="waf">WAF Scanner</option>
            <option value="whois">Default Whois Tool</option>
            <option value="jsdemin">JS Deminifier</option>
            <option value="jsondemin">JSON Deminifier</option>
          </>
        );
      case 'nscan':
        return (
          <>
            <option value="">Select Tool*</option>
            <option value="nscan">Network Scanner</option>
            <option value="dns_res">DNS Resolver</option>
            <option value="ssl_tsl">SSL/TSL</option>
            <option value="pwd_aud">Password Auditor</option>
          </>
        );
      case 'web':
        return (
          <>
            <option value="">Select Tool*</option>
            <option value="web_ser">Web Services</option>
            <option value="wpscan">WordPress Scan</option>
            <option value="xss">Cross Site Scripting XSS</option>
            <option value="sqli">SQL Injection</option>
          </>
        );
      default:
        return <option value="">Select Tool*</option>;
    }
  };

  return (
    <div className="containerscan">
      <div className="text-gradient-scan">Scanners</div>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={textInput}
          onChange={handleTextChange}
          placeholder="Enter Target here*"
          required
        />
        <select value={selectedScanner} onChange={handleScannerChange} required>
          <option value="">Select Scanner*</option>
          <option value="recon">Reconnaissance</option>
          <option value="nscan">Network Scanner</option>
          <option value="web">Web Vulnerebilty Scanner</option>
          
        </select>
        <select value={selectedSubOption} onChange={handleSubOptionChange} required>
          {getSubOptions()}
        </select>
        <button type="submit">Submit</button>
      </form>

      <div className="Result">
        <p style={{ textAlign: 'left' }}>The Scan Result for {textInput}</p>
        <pre style={{ textAlign: 'left' }}>{result}</pre>
      </div>
    </div>
  );
};

export default Scan;
