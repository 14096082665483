// import React, { useState, useEffect, useRef } from 'react';
// // import './App.css';
// import Terminal from 'react-terminal-ui';

// function Terminals() {
//   const [input, setInput] = useState('');
//   const [messages, setMessages] = useState<string[]>([]);
//   const ws = useRef<WebSocket | null>(null);

//   useEffect(() => {
//     // Initialize WebSocket connection
//     ws.current = new WebSocket('ws://localhost:8080');

//     ws.current.onopen = () => {
//       console.log('WebSocket connection opened');
//     };

//     ws.current.onmessage = (event) => {
//       setMessages((prev) => [...prev, event.data]);
//     };

//     ws.current.onclose = () => {
//       console.log('WebSocket connection closed');
//     };

//     return () => {
//       if (ws.current) {
//         ws.current.close();
//       }
//     };
//   }, []);

//   const sendMessage = () => {
//     if (ws.current && input) {
//       ws.current.send(input);
//       setInput('');
//     }
//   };

//   return (
//     <div className="App">
//       <h1>Terminal</h1>
//       <div className="messages">
//         {messages.map((message, index) => (
//           <div key={index} className="message">{message}</div>
//         ))}
//       </div>
//       <input
//         type="text"
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         onKeyPress={(e) => {
//           if (e.key === 'Enter') {
//             sendMessage();
//           }
//         }}
//       />
//       <button onClick={sendMessage}>Send</button>
//     </div>
//   );
// }

// export default Terminals;

import React, { useState, useEffect, useRef } from 'react';
import './terminal.css'; // Import your CSS file
import Nav from '../NavBar/nav';

function Terminals() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<string[]>([]);
  const terminalRef = useRef<HTMLDivElement>(null);
  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    // Initialize WebSocket connection
    ws.current = new WebSocket(' wss://69db-39-57-197-7.ngrok-free.app');

    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
    };

    ws.current.onmessage = (event) => {
      setMessages((prev) => [...prev, event.data]);
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  useEffect(() => {
    // Scroll to bottom when messages change
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = () => {
    if (ws.current && input) {
      ws.current.send(input);
      setInput('');
    }
  };

  return (
    <div className="terminal-container">
      <Nav/>
      <div className="terminal" ref={terminalRef}>
        {messages.map((message, index) => (
          <div key={index} className="message">{message}</div>
        ))}
      </div>
      <div className="input-container">
        <input
          className='input_box'
          type="text"
          value={input}
          placeholder="Enter your command here"
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default Terminals;
