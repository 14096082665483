// Importing the checkmark SVG

import './OSCard.css'
import CheckmarkIcon from '../Assets/CheckMark.svg';

interface Props {}

// Card Component
const OSCard = (props:Props) => {
  return (
    <div className="OScard-container">
      <div className="card-title">Offensive Security</div>
      <ul className='OSCard-list'>
      <li className="checkmark-section">
        <div className="header-text"><img src={CheckmarkIcon}/>Rapid Attack Surface Mapping:</div>
        <div className="body-text">
          Quickly map the attack surface of your target.
        </div>
      </li>
      <li className="checkmark-section">
        <div className="header-text"><img src={CheckmarkIcon}/>Fire-and-Forget Vulnerability Assessments:</div>
        <div className="body-text">
          Run web and network vulnerability scanners for swift discovery of low-hanging fruits.
        </div>
      </li>
      <li className="checkmark-section">
        <div className="header-text"><img src={CheckmarkIcon}/>CVE Walks</div>
        <div className="body-text">Validate CVEs</div>
      </li>
      <li className="checkmark-section">
        <div className="header-text"><img src={CheckmarkIcon}/>Generate Pentest Reports:</div>
        <div className="body-text">
          Utilize the Pentest Report Generator to create editable Word (.docx) reports from your findings.
        </div>
      </li>
      <li className="checkmark-section">
        <div className="header-text"><img src={CheckmarkIcon}/>Automation for Recurring Tasks:</div>
        <div className="body-text">
          Save time for manual analysis by automating recurring tasks.
        </div>
      </li>
      </ul>
    </div>
  );
};

export default OSCard;
