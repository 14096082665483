import React from 'react';
import './footer.css';


interface Props {}


const Footer =(props:Props) => {
  
    return (
     
        <div className="footer">
      <div className="footer-section">
        <h4 className="footer-heading">TOOLS</h4>
        <a href="/scanpage" className="footer-item">Reconnaissance Tools</a>
        <a href="/scanpage" className="footer-item">Web Vulnerability Scanners</a>
        <a href="/scanpage" className="footer-item">Network Vulnerability Scanners</a>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">RESOURCES</h4>
        <a href="/comingsoon" className="footer-item">Blog</a>
        <a href="/comingsoon" className="footer-item">Security Research</a>
        <a href="/comingsoon" className="footer-item">API Reference</a>
        <a href="/comingsoon" className="footer-item">Vulnerability & Exploits</a>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">COMPANY</h4>
        <a href="/comingsoon" className="footer-item">About</a>
        <a href="/comingsoon" className="footer-item">Team</a>
        <a href="/comingsoon" className="footer-item">Customers</a>
        <a href="/comingsoon" className="footer-item">Reviews</a>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">HELP</h4>
        <a href="/comingsoon" className="footer-item">Terms & Conditions</a>
        <a href="/comingsoon" className="footer-item">Privacy Policy</a>
        <a href="/comingsoon" className="footer-item">Frequently Asked Questions</a>
        <a href="/comingsoon" className="footer-item">Support Center</a>
        <a href="/comingsoon" className="footer-item">Contact Us</a>
      </div>
    </div>
    );
};

export default Footer;
