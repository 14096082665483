import React, { useState } from 'react';
import './nav.css';
import info from '../Assets/Info-Circle.svg'
import users from '../Assets/Users.svg'
import review from '../Assets/Message.svg'
import msg from '../Assets/Messages.svg'
import recon from '../Assets/Binoculars.svg'
import web from '../Assets/Globe.svg'
import net from '../Assets/Sitemap.svg'
import machine from '../Assets/Monitor.svg'
import sldx from '../Assets/Shield-X.svg'
import sldc from '../Assets/Shield-Check.svg'
import sup from '../Assets/Headset.svg'
import api from '../Assets/Book-Open.svg'
import blog from '../Assets/Invoice.svg'
import log from '../Assets/Edit.svg'
import faq from '../Assets/Question-Circle.svg'
import tick from '../Assets/tick.svg'
import drop from '../Assets/dropdown.png'
interface Props {}

const Nav =(props:Props) => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  
    return (
      <nav className={`navbar ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
      <div className="container">
        <div className="navbar-logo">
          <a className='logo-a' href='/'><p>SecurProbe</p></a>
        </div>
        <div className={`hamburger-menu ${isMobileMenuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`navbar-menu ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
          <li className="navbar-item dropdown">
            <p className="navbar-link">Product <img src={drop}/></p>
            <div className="dropdown-content">
            <div className="gradient-liness"></div>
              <div className="column2">
                <h4>Key Features</h4>
                <a className='rcon' href="#">
                  <h5>Built in Vulnerebility Scanner</h5>
                  <p>Discover vulnerabilities in web apps<br></br> and network infrastructures</p></a>
                <a href="#">
                  <h5>Pentest Report Generator</h5>
                  <p>Create editable Word (.docx) pentest<br></br> reports, ready to be delivered</p>
                </a>
                <a href="#">
                  <h5>Pentest Robots</h5>
                  <p>Chain multiple tools into custom<br></br> testing flows to replace manual tasks</p>
                </a>
               </div>
               
             <div className='column22'>
             <div className="gradient-line"></div>
                    <h4>Pentesting Tools</h4>
                    <a href="#"><h6><img src={recon}/> Reconnaissance</h6></a>
                    <a href="#"><h6><img src={web}/> Web Scanner</h6></a>
                    <a href="#"><h6><img src={net}/> Network Scanner</h6></a>
                    <a href="/terminal"><h6><img src={machine}/> Access Machine</h6></a>
                  </div>
                    
            </div>
          </li>
          {/* Add more menu items as needed */}
          {/* <li className="navbar-item cve">
            <a href='#' className="navbar-link">CVE</a>
          </li> */}
          {/* Add more menu items as needed */}
          <li className="navbar-item dropdown">
            <p className="navbar-link">Resources <img src={drop}/></p>
            <div className="dropdown-content">
            <div className="gradient-liness"></div>
              <div className="column">
                <h4>Resources</h4>
                <a className='rcon' href="#">
                  <h5><img src={sldx}/> Vulnerabilities & Exploits</h5>
                  <p>A database of vulnerabilities you can detect and <br></br>exploit with our platform.</p></a>
                <a href="#">
                  <h5><img src={blog}/> Blog</h5>
                  <p>Come for the technical walkthroughs. stay for<br></br> the practical tips from offensive security pros.</p>
                </a>
                <a href="#">
                  <h5><img src={log}/> Changelog</h5>
                  <p>New tools, detections, exploits, textures, and<br></br> improvements were launched.</p>
                </a>
                <a href="#">
                  <h5><img src={api}/> API Reference</h5>
                  <p>How to integrate our platform into your security<br></br> ecosystem.</p>
                </a>
                </div>
                <div className='column'>
                <h3 className='trans'>&</h3>
                <a href="#">
                  <h5><img src={sldc}/> Data Privacy & Security</h5>
                  <p>Data protection highlights if you don't want to<br></br> read our T&Cs.</p>
                </a>
                <a href="#">
                  <h5><img src={faq}/> FAQ</h5>
                  <p>One way to find out more about the platform.</p>
                </a>
                <a href="#">
                  <h5><img src={sup}/> Support</h5>
                  <p>How-to guides on how to use the entire<br></br> platform, plus contact options.</p>
                </a>
               </div>
            </div>
          </li>
          {/* Add more menu items as needed */}
          <li className="navbar-item dropdown">
            <p className="navbar-link">Company <img src={drop}/></p>
            <div className="dropdown-content">
            <div className="gradient-liness"></div>
              <div className="column">
                <h4>Company</h4>
                <a className='rcon' href="#">
                  <h5><img src={info}/> About</h5>
                  <p>What we do at SecurProbe.com and why we do it.</p></a>
                <a href="#">
                  <h5><img src={users}/> Team</h5>
                  <p>Meet the people who build and grow SecurProbe.com</p>
                </a>
                <a href="#">
                  <h5><img src={review}/> Reviews</h5>
                  <p>See how other security and IT Pros use the platform.</p>
                </a>
                <a href="#">
                  <h5><img src={msg}/> Contact</h5>
                  <p>Here's how to get in touch with us directly.</p>
                </a>
               </div>
            </div>
          </li>
          {/* Add more menu items as needed */}
          <ul className='nav-btn-list-sm'>
          {/* <li className="navbar-button">
          <a href='/login'><button className="btn">Login</button></a> 
        </li> */}
        <li className={`navbar-freescan-button ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
        <a href='/scanpage'><button className="btn">Free Scans</button></a>
          
          <div className="dropdown-scan">
            {/* <div className='dropdown-scan-content'>
               <h6>Create an account to access the <b>full<br></br>capabilities of our tools</b>, plus:</h6>
               <ul className='scan-list'>
                <li><p><img src={tick}/>scheduling</p></li>
                <li><p><img src={tick}/>reporting</p></li>
                <li><p><img src={tick}/>autmation and more</p></li>
               </ul>
               <div className='signup-btn'>
               <a href='/signup'><button className='btn-signup'>Sign Up Now</button></a>
               </div>
               </div> */}
               
            </div>
        </li>
        </ul>
      </ul>

       <ul className='nav-btn-list-lg'>
        <li className={`navbar-freescan-button ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
          <a href='/scanpage'><button className="btn">Free Scans</button></a>
          <div className="dropdown-scan">
            {/* <div className='dropdown-scan-content'>
               <h6>Create an account to access the <b>full<br></br>capabilities of our tools</b>, plus:</h6>
               <ul className='scan-list'>
                <li><p><img src={tick}/>scheduling</p></li>
                <li><p><img src={tick}/>reporting</p></li>
                <li><p><img src={tick}/>autmation and more</p></li>
               </ul>
             </div> */}
               {/* <div className='signup-btn'>
               <a href='/signup'><button className='btn-signup'>Sign Up Now</button></a>
               </div> */}
            </div>
        </li>
        {/* <li className="navbar-button">
        <a href='/login'><button className="btn">Login</button></a>
        </li> */}
        </ul>
      </div>
    </nav>
    
    );
};

export default Nav;
