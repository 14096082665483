// Importing the checkmark SVG

import './DSCard.css'
import CheckmarkIcon from '../Assets/CheckMark.svg';

interface Props {}

// Card Component
const DSCard = (props:Props) => {
  return (
    <div className="DScard-container">
    <div className="card-title">Defensive Security</div>
    <ul className='DSCard-list'>
    <li className="checkmark-section">
      <div className="header-text"><img src={CheckmarkIcon}/>Web Application Vulnerability Scans (including behind login):
      </div>
      <div className="body-text">
        Run thorough scans to identify vulnerabilities in web applications, even those behind login portals.
      </div>
    </li>
    <li className="checkmark-section">
      <div className="header-text"><img src={CheckmarkIcon}/>Network Vulnerability Scans:</div>
      <div className="body-text">
        Identify missing patches, misconfigurations, and other network vulnerabilities for robust security.
      </div>
    </li>
    <li className="checkmark-section">
      <div className="header-text"><img src={CheckmarkIcon}/>Continuous Asset Discovery and Shadow IT Identification:
      </div>
      <div className="body-text">
        Continuously discover assets and uncover shadow IT to maintain a comprehensive security posture.
      </div>
    </li>
    <li className="checkmark-section">
      <div className="header-text"><img src={CheckmarkIcon}/>Scan Internal and Public-Facing Assets:</div>
      <div className="body-text">
        Extend coverage by scanning both internal applications and public facing assets.
      </div>
    </li>
    <li className="checkmark-section">
      <div className="header-text"><img src={CheckmarkIcon}/>API Integration for Workflow Efficiency:</div>
      <div className="body-text">
        Seamlessly integrate scanners into your workflow using the API for enhanced automation and efficiency.
      </div>
    </li>
    </ul>
  </div>
  );
};

export default DSCard;
