
import { Outlet } from 'react-router';
import './App.css';
import Footer from './pages/Footer/footer';


function App() {
  return (
    <>
    <Outlet/>
    {/* <style>
        {`
          body {
            margin: 0;
            padding: 0;
            background-color: #171717;
          }
        `}
      </style> */}
    </>
    
  );
}

export default App;
