import React from 'react';
import './home.css';
import Nav from '../NavBar/nav';
import Footer from '../Footer/footer'
import vector3 from '../Assets/Vector3.svg';
import vector4 from '../Assets/Vector4.svg';
import text_main from '../Assets/text-main.svg';
import { Container } from 'react-bootstrap';
import clock from '../Assets/clock.svg';
import doc from '../Assets/doc.svg';
import money from '../Assets/money.svg';
import WSCard from '../WSCard/WSCard'
import DSCard from '../DSCard/DSCard'
import OSCard from '../OSCard/OSCard';
import ASA from '../ASACard/ASA';
import WPR from '../WPRCard/WPR';
import VS from '../VSCard/VS';
import Scan from '../ScanComponet/Scan';

interface Props {}
interface ColumnProps {
  imageUrl: string;
  text: string;
}

const Column: React.FC<ColumnProps> = ({ imageUrl, text }) => (
  <div className="column-home">
    <img className="column-image" src={imageUrl} alt="Column-icon" />
    <p className="column-text">{text}</p>
  </div>
);

const Home =(props:Props) => {
  
    return (
      <div className='main'>
       <Nav/>
       <Container className='heading-container'>
       <div className="label">
          <img className="the-essential" alt="The essential" src={text_main}/>
       </div>
       <div className="label">
        <p className="pentest-tools-com">
          SecurProbe.com makes it easy for security teams to discover, exploit and report common
          vulnerabilities <br/>while saving time for custom work and more creative hacking.</p>
       </div>
          <div className="columns-container">
            <Column imageUrl={clock} text="Reduce repetitive pentesting works" />
            <Column imageUrl={doc} text="Write pentest reports 50% faster" />
           <Column imageUrl={money} text="Eliminate the cost of multiple scanners" />
          </div>
          <div className="box2">
          <img className="vector" alt="Vector" src={vector4} />
        </div>
        <div className="box1">
          <img className="vector" alt="Vector" src={vector3} />
        </div>
       </Container>

       <div className='text-gradient'>What you can do with SecurProbe.com</div>
       <p className='text-content'>Built by a team of experienced penetration testers, Pentest-Tools.com is a web-based platform that speeds-up
the common steps performed in almost every assessment: <br></br>reconnaissance, vulnerability scanning,
exploitation, and report writing. Using the 20+ built-in tools, you get quick insights into targets' weaknesses so
you know where to dig deeper, pop shells, and have fun.</p>
      

      <Container className='hori-cards'>
        <ASA/>
        <VS/>
        <WPR/>

      </Container>
        <div className="text-gradient">SecurProbe.com is for:</div>
    <Container className='cards'>
      <OSCard/>
      <DSCard/>
      <WSCard/>
    </Container>
        <div className="text-gradient">You're backed by a strong team</div>
        <div className="text-content"><p>Our journey has begun in 2023 with a vision to revolutionize penetration testing tools, and today, it's led by a dedicated
  team who continue to guide product development.</p>
  <p>Since its inception (Beta version), SecurProbe.com has evolved into an ongoing project. We are committed to refining and expanding its
  capabilities, focusing on accuracy, speed, and flexibility. Our team leverages practical experience and industry knowledge to incorporate
  new security testing techniques, enhance workflows, and provide detections for the latest vulnerabilities.</p>
  <p>Currently, we are actively working on adding more modules to uncover every possible aspect of penetration testing. This ongoing commitment
  reflects our dedication to ensuring SecurProbe.com remains at the forefront of innovation, empowering our users to achieve comprehensive security
  assessments and deliver maximum value from their tests.</p>
</div>
  <Footer/>

 </div>
    );
};

export default Home;
