// Importing the checkmark SVG

import './ASA.css'
import CheckmarkIcon from '../Assets/CheckMark.svg';
import recon from '../Assets/attack-surface-mapping-illustration.svg'


interface Props {}

// Card Component
const ASA = (props:Props) => {
  return (
    <div className='card-horizontal'>
    <div className='card-column'>
    <div className='title-gradient'>Attack Surface Analysis</div>
    <ul className='checklist-text'>
      <li><img src={CheckmarkIcon}/>Discover the attack surface of your network targets, including subdomain <br></br>
enumeration, open ports and running services</li>
<li><img src={CheckmarkIcon}/>Map web application technologies, detectWAFs and discover hidden files</li>
<li><img src={CheckmarkIcon}/>Popular tool: Subdomain Finder, TCP port Scanner URL Fuzzer</li>
<li><img src={CheckmarkIcon}/>Utils: such as ICMP ping and Whois lookup</li>
    </ul>
   <a href='/scanpage'><button className='card-btn'><span className='card-btn-txt'>Explore Recon Tools</span></button></a>
  </div>
  <div className='card-column'>
    <img className='asa-img' src={recon}/>
  </div>
  </div>
  );
};

export default ASA;
